import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { BaseLayout, En, ExternalLink } from '../components'

export default function Page() {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'ja' }}>
        <title>プライバシーポリシー</title>
        <meta name="og:title" content="プライバシーポリシー" />
      </Helmet>
      <BaseLayout>
        <h1>プライバシーポリシー</h1>
        <p>
          発行日:<Time dateTime="2021-01-22">2021年1月22日</Time>
        </p>

        <h2>当サイトにおける取得情報の取り扱いについて</h2>

        <h3>アクセス解析及び広告サービスにおける取得情報について</h3>

        <p>
          当サイトでは、<En>Google Analytics</En>を利用してアクセス解析を行っています。<En>Google Analytics</En>はアクセス解析のために<En>Cookie</En>を使用しますが、<En>Cookie</En>には個人を特定できる情報（氏名、住所、メールアドレス、電話番号等）は含まれていません。<En>Google Analytics</En>の利用規約については<ExternalLink to="https://marketingplatform.google.com/about/analytics/terms/jp/">こちら</ExternalLink>をご覧ください。
        </p>

        <p>
          また、当サイトでは、第三者配信の広告サービスとして<En>Google AdSense</En>を利用しています。<En>Google AdSense</En>はユーザーの興味に応じて商品やサービスの広告を表示するため、当サイトや他サイトへのアクセスに関する情報として<En>Cookie</En>(氏名、住所、メールアドレス、電話番号は含まれません)を使用することがあります。詳細については、<ExternalLink to="https://policies.google.com/technologies/ads?gl=jp&hl=ja">こちら</ExternalLink>をご覧ください。
        </p>

        <p>
          なお、<En>Cookie</En>の使用を許可するかどうかはユーザーが設定できます。ウェブブラウザーのほとんどが初期設定で<En>Cookie</En>の使用を許可する状態になっていますが、必要に応じて<En>Cookie</En>を禁止するように設定を変更することもできます。上述のアクセス解析、及びユーザーの行動履歴に応じた広告配信を無効化したい場合はご検討ください。
        </p>

        <h3>お問い合わせフォームにおける取得情報について</h3>

        <p>当サイトではユーザーからのご意見やご質問などをお問い合わせフォームにてお受けしています。その際に、お問い合わせに対してメールで返信をするためにメールアドレスを入力していただいております。このメールアドレスはメールの返信のためだけに利用し、その他の用途では一切使用しません。返信後はメールアドレスの情報を破棄します。</p>
      </BaseLayout>
    </>
  )
}

const Time = styled.time`
  display: inline-block;
  margin-left: 0.5rem;
`
